import React from "react";

function Footer() {
  return (
    <div className=" bg-white py-3 px-4 shadow-md text-sm">
      2024© COD Lanka
    </div>
  );
}

export default Footer;
